<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" export print custom>
      <template #buttons>
        <el-button type="success" size="mini" @click="itemAdd">新增参数</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      :max-height="this.$util.getViewHeight() - 150"
      border
      show-overflow
      highlight-current-row
      :row-config="{ useKey: true, keyField: 'id' }"
      :export-config="{
        type: ['xlsx'],
        types: ['xlsx', 'csv', 'html', 'xml', 'txt'],
        isFooter: true,
        isColgroup: true
      }"
      :tree-config="{
        transform: false,
        expandAll: false,
        showLine: true,
        childrenField: 'children',
        reserve: true
      }"
      :data="tableDataMain"
      :columns="tableColumn"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_operate="{ row }">
        <!-- <vxe-button icon="el-icon-view" title="详情" circle @click="itemDetail(row)" /> -->
        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 增改参数弹窗 -->
    <PopupDict
      v-if="isShowPopupDict"
      :show.sync="isShowPopupDict"
      :data.sync="itemObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupDict from './popup-dict'
export default {
  components: { PopupDict },
  data() {
    return {
      // 图片end
      headerFilterUrl: '/admin/gcvip/dict/searchHeard',
      tableColumn: [
        {
          field: 'id',
          treeNode: true,
          width: 200,
          title: '序号',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'name',
          title: '名称',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'value',
          title: '值',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'remark',
          title: '说明',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'order_id',
          width: 100,
          title: '顺序',
          slots: { header: 'header_autocomplete' }
        },
        { title: '操作', width: 160, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page
      },
      filterData: {},
      sortData: { order_id: 'asc' },

      isShowPopupDict: false,
      itemDefault: {
        id: '',
        name: '',
        value: '',
        order_id: '',
        remark: ''
      },
      itemObj: {}
    }
  },
  created() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联
      this.$refs.refTable.connect(this.$refs.refToolbar)
      this.initItemObj()
      this.searchListData()
    })
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索数据列表
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取数据列表
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dict/gcvipDictSearchTree',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          sortData: this.sortData,
          filterData: this.filterData
        }
      }).then((res) => {
        if (res.status === 200) {
          this.tableDataMain = res.data.page.list
          this.pageOptions.pageSize = res.data.page.pageSize
          this.pageOptions.total = res.data.page.totalRow
          this.pageOptions.currentPage = res.data.page.pageNumber
        }
      })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupDict = true
      this.initItemObj()
    },
    // 项-详情
    itemDetail(row) {
      this.$router.push({ path: '/base/dict/detail', query: { id: row.id } })
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupDict = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          // 传送删除动作
          this.$api({
            method: 'post',
            url: '/admin/gcvip/dict/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
