<template>
  <div class="box-dialog">
    <el-dialog
      width="420px"
      :title="selfData.id ? '编辑参数' : '新增参数'"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" :model="selfData" :rules="rules" label-width="100px">
        <el-form-item label="名称:" prop="name">
          <el-input v-model="selfData.name" size="mini" />
        </el-form-item>
        <el-form-item label="说明:" prop="remark">
          <el-input v-model="selfData.remark" size="mini" />
        </el-form-item>
        <el-form-item label="值:" prop="value">
          <el-input v-model="selfData.value" size="mini" />
        </el-form-item>
        <el-form-item label="顺序:" prop="order_id">
          <el-input v-model="selfData.order_id" size="mini" />
        </el-form-item>
        <el-form-item label="所属分类:" prop="father_id">
          <el-cascader
            style="width: 100%"
            size="mini"
            clearable
            v-model="selfData.p_id"
            :options="tableDataMainAll"
            :props="{ checkStrictly: true, value: 'id', label: 'name' }"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {},
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      tableDataMainAll: []
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
    this.searchAllTree()
  },
  methods: {
    searchAllTree() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dict/gcvipDictSearchTree',
        data: {
          currentPage: 1,
          pageSize: 500
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.tableDataMainAll = res.data.page.list
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/dict/saveOrUpdateDict',
            data: this.selfData
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.popupCancel()
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
